<template>
  <div class="invoices-header">
    <div class="invoices-header-title">
      <h3>{{ $t("Invoices") }}</h3>
    </div>

    <div style="padding-left: 48px; height: 42px; margin-top: 0; display: flex;">
      <div
          @click="changeActiveTab('invoices')"
          :class="
          activeTab === 'invoices'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
      >
        {{ $t("Invoices") }}
      </div>
      <div
          v-if="showBrokersTab"
          @click="changeActiveTab('brokers')"
          :class="
          activeTab === 'brokers'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
          style="margin-left: 24px"
      >
        {{ $t("Brokers") }}
      </div>
    </div>

    <HeaderToolbar :module="'invoices'">
      <div class="invoices-header-content">
        <BaseButton :icons-style="{height: '16px', width: '16px'}" @click="() => openInvoiceAddPanel()"
                    icon-left="action-add.svg" :primary="true" :label="$t('AddInvoice')"/>
        <FilterDropdown
            filter-name="invoices"
        />
        <div>•</div>
        <v-text-field
            autocomplete="off"
            outlined
            v-model="search"
            hide-details
            :id="'search-bar'"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search') + '...'"
            style="background: white; max-width: 240px;"
        />
       <!--  <GroupDropdown
            :key="'colleagues-' + colleagueUpdater"
            v-show="!user.config"
            :display-key="'name'"
            :value-key="'_id'"
            :search-box="true"
            :default-item="colleagues[0]"
            :items="colleagues"
            :groups="groups"
            :selected="selectedUser"
            @itemSelected="getBrokerInvoices"
        /> -->
        <GroupOrUserSelect v-model="chosenUsers" module="transactions"/>
        <DropdownMulti
            :items="invoiceTypes"
            v-model="selectedInvoiceTypes"
            :display-key="'name'"
            :value-key="'value'"
            :width="'160px'"
            :height="'34px'"
            :font-size="'12px'"
            :default-value="$t('AllInvoiceTypes')"
          />
        <DropdownMulti
          :items="invoiceStatuses"
          v-model="selectedInvoiceStatus"
          :display-key="'name'"
          :value-key="'value'"
          :width="'160px'"
          :height="'34px'"
          :font-size="'12px'"
          :default-value="$t('AllStatuses')"
        />
        <div>
          <div class="invoice-radio-group">
            <div @click="getByInvoiceAccountingType('accrual_accounting')"
                 :class="invoiceAccountingType === 'accrual_accounting' ? 'invoice-radio-group-item-selected' : 'invoice-tag'"
                 class="invoice-radio-group-item content-small">
              {{ $t("AccrualAccounting") }}
            </div>
            <div @click="getByInvoiceAccountingType('cash_accounting')"
                 :class="invoiceAccountingType === 'cash_accounting' ? 'invoice-radio-group-item-selected' : 'invoice-tag'"
                 class="invoice-radio-group-item content-small">
              {{ $t("CashAccounting") }}
            </div>
          </div>
        </div>
        <p class="label12">{{$t("InvoiceDate") + ':'}}</p>
        <Dropdown
          v-if="pastDayOptions"
          :items="pastDayOptions"
          :display-key="'name'"
          :value-key="'name'"
          :default-item="pastDayOptions[4]"
          :selected="invoiceDateFilter"
          @itemSelected="filterByInvoiceDate"
        />
        <v-row v-if="invoiceCreatedCustomSelected" style="gap: 8px">
            <label class="label12"> {{ $t("From") }}: </label>
            <BaseDatePicker
              :selected-from-date="invoiceDateFromFilter"
              :fromDate="true"
              @dateFromChosen="filterByCreatedFromDate"
            />

            <label class="label12"> {{ $t("To") }}: </label>
            <BaseDatePicker
              :selected-to-date="invoiceDateToFilter"
              :toDate="true"
              @dateToChosen="filterByCreatedToDate"
            />
        </v-row>
      </div>
    </HeaderToolbar>
  </div>
</template>

<script>
import FilterDropdown from "@/components/common/FilterDropdown";
import BaseButton from "@/components/common/BaseButton";
import {mapActions, mapGetters} from "vuex";
import DropdownMulti from "@/components/common/DropdownMulti";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import Dropdown from "@/components/common/Dropdown";
import BaseDatePicker from "@/components/common/BaseDatePicker";
//import moment from "moment";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";
import axios from "axios";
export default {
  name: "Header",
  props: ["headerData"],
  components: {HeaderToolbar, DropdownMulti, FilterDropdown, BaseButton, GroupOrUserSelect, Dropdown, BaseDatePicker},
  data() {
    return {
      chosenUsers: null,
      initialized: false,
      search: "",
      invoiceDateFilter: {},
      invoiceAccountingType: "",
      invoiceDateFromFilter: "",
      invoiceDateToFilter: "",
      invoiceTypes: [
        {name: this.$t("RentalMediation"), value: "rental_mediation"},
        {name: this.$t("SalesMediation"), value: "sales_mediation"},
        {name: this.$t("RepresentationOfThePurchasingCustomer"), value: "representation_of_the_purchasing_customer"},
        {name: this.$t("Consultation"), value: "consultation"},
        {name: this.$t("Referral"), value: "referral_fee"},
        {name: this.$t("Appraisal"), value: "appraisal"},
        {name: this.$t("Collaboration"), value: "collaboration"},
        {name: this.$t("Other"), value: "other"},
      ],
      invoiceStatuses: [
        {name: this.$t("MakeInvoice"), value: "make_invoice"},
        {name: this.$t("Paid"), value: "paid"},
        {name: this.$t("PartiallyPaid"), value: "partially_paid"},
        {name: this.$t("NotPaid"), value: "not_paid"},
        {name: this.$t("Overdue"), value: "overdue"}
      ],
      groups: [
        {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
      ],
      colleagueUpdater: 0,
      dropdownsUpdater: 0,
      selectedUser: null,
      activeTab: "",
      selectedInvoiceTypes: [],
      selectedInvoiceStatus: []
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "activeInvoicesTab"
    ]),
    showBrokersTab() {
      return this.user.access.accessLevel === 'group_admin'
        || this.user.access.accessLevel === 'company_admin'
        || this.user.companyPolicies?.filters?.transactions?.showAll
    },
    invoiceCreatedCustomSelected() {
      return this.provisionalFilters.invoices
        .find((condition) => condition.name === "invoiceDate")
        .value === "custom";
    },
    colleagues() {
      if (this.user.isAdmin || this.user.companyPolicies.filters.transactions.showAll) {
        return [
          {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
        ].concat(this.users)
      } else {
        let users = this.users.filter((user) => user.adminAccess);
        if (users.length > 0) {
          return [
            {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
          ].concat(users)
        } else {
          return []
        }
      }
    },
    pastDayOptions() {
      return [
        {
          name: this.$t("Today"),
          value: "today",
        },
        {
          name: this.$t("Yesterday"),
          value: "yesterday",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastDays"),
          value: "last_3_days",
        },
        {
          name: this.$t("InLast") + " 14 " + this.$t("InLastDays"),
          value: "last_14_days",
        },
        {
          name: this.$t("ThisMonth"),
          value: "this_month",
        },
        {
          name: this.$t("LastMonth"),
          value: "last_month",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastMonths"),
          value: "last_3_months",
        },
        {
          name: this.$t("CurrentYear"),
          value: "current_year",
        },
        {
          name: this.$t("InLast") + " 12 " + this.$t("InLastMonths"),
          value: "last_year",
        },
        {
          name: this.$t("Custom"),
          value: "custom",
        },
      ];
    },
  },
  async created() {
    await this.getActiveTab()
    const accountingFilter = this.provisionalFilters["invoices"].find(filter => filter.name === 'accountingType');
    if (!accountingFilter || (accountingFilter && accountingFilter.value === '')) {
      await this.getByInvoiceAccountingType('accrual_accounting')
    }
    this.loadFiltersToFields();
  },
  watch: {
    chosenUsers: async function (value) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "brokerId",
        value: value,
        valueTitle: "",
      });
    },
    search: async function (value) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "search",
        value: value,
      });
    },
    provisionalFiltersUpdater() {
      this.loadFiltersToFields()
    },
    selectedInvoiceTypes: {
      handler(newVal) {
        let invoiceTypes = [];
        newVal.forEach((type) => {
          invoiceTypes.push(type.value);
        })
        this.changeProvisionalFilterCondition({
          filterName: "invoices",
          conditionName: "invoiceTypes",
          value: invoiceTypes,
          valueTitle: invoiceTypes.join(", ")
        })
      },
      deep: true,
    },
    selectedInvoiceStatus: {
      handler(newVal) {
        let invoiceStatuses = [];
        newVal.forEach((status) => {
          invoiceStatuses.push(status.value);
        })
        this.changeProvisionalFilterCondition({
          filterName: "invoices",
          conditionName: "status",
          value: invoiceStatuses,
          valueTitle: invoiceStatuses.join(", ")
        })
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "changeProvisionalFilterCondition",
      "setActiveInvoicesTab",
      "openInvoiceAddPanel",
    ]),
    async getActiveTab() {
      if (this.activeInvoicesTab) {
        this.activeTab = this.activeInvoicesTab;
      } else {
        this.activeTab = "invoices";
        await this.setActiveInvoicesTab('invoices')
      }
    },
    async changeActiveTab(value) {
      this.setActiveInvoicesTab(value)
      this.activeTab = value;
    },
    loadFiltersToFields() {
      this.provisionalFilters["invoices"].forEach((filterCondition) => {
        switch (filterCondition.name) {
          case "search":
            this.search = filterCondition.value;
            break;
          case "brokerId":
            if (filterCondition.value) {
              if (!filterCondition.value.type || (filterCondition.value.type && !filterCondition.value.value)) {
                filterCondition.value = null;
              }
            } else {
              filterCondition.value = null;
            }
            this.chosenUsers = filterCondition.value
            break;
          case "accountingType":
            this.invoiceAccountingType = filterCondition.value;
            break;
          case "invoiceDate":
            if (filterCondition.value) {
              this.invoiceDateFilter = this.pastDayOptions.find((item) => item.value === filterCondition.value)
            } else {
              this.invoiceDateFilter = this.pastDayOptions[4]
            }
            break;
          case "invoiceDateFrom":
            if (filterCondition.value) {
              this.invoiceDateFromFilter = filterCondition.value
            } 
            break;
          case "invoiceDateTo":
            if (filterCondition.value) {
              this.invoiceDateToFilter = filterCondition.value
            } 
            break;
         /*  case "invoiceDateType":
            this.invoiceDateType = filterCondition.value;
            break;
          case "dateOfInvoiceFromDate":
            this.invoiceDateFrom = filterCondition.value;
            break;
          case "dateOfInvoiceToDate":
            this.invoiceDateTo = filterCondition.value;
            break; */
        }
      })
      this.initialized = true;
      this.dropdownsUpdater++;
    },
    async getByInvoiceAccountingType(selectedItem) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "accountingType",
        value: selectedItem,
        valueTitle: selectedItem
      })
    },
    /* async getByInvoiceDateType(selectedItem) {
      if (selectedItem === 'custom') {
        await this.filterByFromDate(moment().format('YYYY-MM-DD'))
      }
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "invoiceDateType",
        value: selectedItem,
        valueTitle: selectedItem
      })
    }, */
    /* async filterByToDate(date) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "dateOfInvoiceToDate",
        value: date,
        valueTitle: date
      })
    },
    async filterByFromDate(date) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "dateOfInvoiceFromDate",
        value: date,
        valueTitle: date
      })
    }, */
    async filterByInvoiceDate(newTimeFrame) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "invoiceDate",
        value: newTimeFrame.value,
        valueTitle: newTimeFrame.name,
      });
    },
    async filterByCreatedFromDate(fromDate) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "invoiceDateFrom",
        value: fromDate,
        valueTitle: fromDate,
      });
    },
    async filterByCreatedToDate(toDate) {
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "invoiceDateTo",
        value: toDate,
        valueTitle: toDate,
      });
    },
    async getBrokerInvoices(selectedItem) {
      let value = [];
      if (selectedItem.users) {
        selectedItem.users.forEach((user) => {
          value.push(user._id);
        })
      } else if (selectedItem.value !== "allBrokers" && !selectedItem.users) {
        value.push(selectedItem._id);
      }
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "brokerId",
        value: selectedItem.value === "allBrokers" ? 0 : value,
        valueTitle:
            selectedItem.value === "allBrokers"
                ? "allBrokers"
                : selectedItem.name,
      });
    },
    async getCompanyGroups() {
      const groupResponse = await axios.get(`/api/get-groups/${this.user.companyId}`);
      groupResponse.data.groups.forEach((group) => {
        this.groups.push(group);
      })
      this.colleagueUpdater++;
    },
    async getColleagues() {
      const response = await axios.get("/api/users");
      const users = response.data.users;
      users.forEach((user) => {
        if (user.userStatus === 'Active') {
          this.colleagues.push(user);
        }
      });
    },
    async getByInvoiceType(selectedItem) {
      let invoiceTypes = [];
      selectedItem.forEach((type) => {
        invoiceTypes.push(type.value);
      })
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "invoiceTypes",
        value: invoiceTypes,
        valueTitle: invoiceTypes.join(", ")
      })
    },
    async getByInvoiceStatus(selectedItem) {
      let invoiceStatuses = [];
      selectedItem.forEach((status) => {
        invoiceStatuses.push(status.value);
      })
      await this.changeProvisionalFilterCondition({
        filterName: "invoices",
        conditionName: "status",
        value: invoiceStatuses,
        valueTitle: invoiceStatuses.join(", ")
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.invoices-header {
  background: #fff;
  margin-left: 64px;
}

.invoices-header-title {
  padding: 16px 48px 24px 48px;
}

.invoices-header-title h3 {
  line-height: 32px !important;
}

.invoices-header-content {
  padding: 16px 64px 16px 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.invoice-radio-group {
  background: #e6e8ec;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1px;
  overflow: hidden;

}

.invoice-radio-group-item {
  padding: 8px;
}

.invoice-tag {
  background: #ffffff;
  cursor: pointer;
}

.invoice-radio-group-item-selected {
  background: $grey;
}

.invoice-status-item:not(:last-child) {
  border-right: 1px solid #e6e8ec;
}

.invoice-custom-date {
  display: flex;
  gap: 8px;
}

.tab-selected {
  padding-bottom: 24px;
  font-weight: 600;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.tab-unselected {
  padding-bottom: 24px;
  cursor: pointer;
}


::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}
</style>