var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invoices-header"
  }, [_c('div', {
    staticClass: "invoices-header-title"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Invoices")))])]), _c('div', {
    staticStyle: {
      "padding-left": "48px",
      "height": "42px",
      "margin-top": "0",
      "display": "flex"
    }
  }, [_c('div', {
    class: _vm.activeTab === 'invoices' ? 'tab-selected content-small' : 'tab-unselected content-small',
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('invoices');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Invoices")) + " ")]), _vm.showBrokersTab ? _c('div', {
    class: _vm.activeTab === 'brokers' ? 'tab-selected content-small' : 'tab-unselected content-small',
    staticStyle: {
      "margin-left": "24px"
    },
    on: {
      "click": function ($event) {
        return _vm.changeActiveTab('brokers');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Brokers")) + " ")]) : _vm._e()]), _c('HeaderToolbar', {
    attrs: {
      "module": 'invoices'
    }
  }, [_c('div', {
    staticClass: "invoices-header-content"
  }, [_c('BaseButton', {
    attrs: {
      "icons-style": {
        height: '16px',
        width: '16px'
      },
      "icon-left": "action-add.svg",
      "primary": true,
      "label": _vm.$t('AddInvoice')
    },
    on: {
      "click": function () {
        return _vm.openInvoiceAddPanel();
      }
    }
  }), _c('FilterDropdown', {
    attrs: {
      "filter-name": "invoices"
    }
  }), _c('div', [_vm._v("•")]), _c('v-text-field', {
    staticStyle: {
      "background": "white",
      "max-width": "240px"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('GroupOrUserSelect', {
    attrs: {
      "module": "transactions"
    },
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.invoiceTypes,
      "display-key": 'name',
      "value-key": 'value',
      "width": '160px',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllInvoiceTypes')
    },
    model: {
      value: _vm.selectedInvoiceTypes,
      callback: function ($$v) {
        _vm.selectedInvoiceTypes = $$v;
      },
      expression: "selectedInvoiceTypes"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.invoiceStatuses,
      "display-key": 'name',
      "value-key": 'value',
      "width": '160px',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllStatuses')
    },
    model: {
      value: _vm.selectedInvoiceStatus,
      callback: function ($$v) {
        _vm.selectedInvoiceStatus = $$v;
      },
      expression: "selectedInvoiceStatus"
    }
  }), _c('div', [_c('div', {
    staticClass: "invoice-radio-group"
  }, [_c('div', {
    staticClass: "invoice-radio-group-item content-small",
    class: _vm.invoiceAccountingType === 'accrual_accounting' ? 'invoice-radio-group-item-selected' : 'invoice-tag',
    on: {
      "click": function ($event) {
        return _vm.getByInvoiceAccountingType('accrual_accounting');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AccrualAccounting")) + " ")]), _c('div', {
    staticClass: "invoice-radio-group-item content-small",
    class: _vm.invoiceAccountingType === 'cash_accounting' ? 'invoice-radio-group-item-selected' : 'invoice-tag',
    on: {
      "click": function ($event) {
        return _vm.getByInvoiceAccountingType('cash_accounting');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CashAccounting")) + " ")])])]), _c('p', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("InvoiceDate") + ':'))]), _vm.pastDayOptions ? _c('Dropdown', {
    attrs: {
      "items": _vm.pastDayOptions,
      "display-key": 'name',
      "value-key": 'name',
      "default-item": _vm.pastDayOptions[4],
      "selected": _vm.invoiceDateFilter
    },
    on: {
      "itemSelected": _vm.filterByInvoiceDate
    }
  }) : _vm._e(), _vm.invoiceCreatedCustomSelected ? _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('label', {
    staticClass: "label12"
  }, [_vm._v(" " + _vm._s(_vm.$t("From")) + ": ")]), _c('BaseDatePicker', {
    attrs: {
      "selected-from-date": _vm.invoiceDateFromFilter,
      "fromDate": true
    },
    on: {
      "dateFromChosen": _vm.filterByCreatedFromDate
    }
  }), _c('label', {
    staticClass: "label12"
  }, [_vm._v(" " + _vm._s(_vm.$t("To")) + ": ")]), _c('BaseDatePicker', {
    attrs: {
      "selected-to-date": _vm.invoiceDateToFilter,
      "toDate": true
    },
    on: {
      "dateToChosen": _vm.filterByCreatedToDate
    }
  })], 1) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }